@import "./components/typography/mixins";
@import "./components/spacing/functions";
@import "./components/alert/mixins";

/*
==================================================
    /Contact page
==================================================
*/
.contact-wrapper {
  display: flex;
  flex-direction: row-reverse;
}

.contact-form {
  width: 100%;
}

.map, .contact-content-wrapper {
  flex: 1;
}

.map {
  min-height: 100vh;
}

.contact-content-wrapper {
  margin-top: 80px;
}

/*
==================================================
    /Home page/Landing
==================================================
*/
.landing-container {
  width: calc(5 / 12 * 100%);
}

.landing-title {
  @include title-fz-3;
}

/*
==================================================
    /Home page/Alsace
==================================================
*/
.alsace {
  width: calc(8 / 12 * 100%);
}

/*
==================================================
    /Home page/Discover
==================================================
*/
.discover-card {
  width: calc(3 / 12 * 100%);
}

.discover-map {
  height: 46vh;
}

.discover {
  padding-top: 23vh;
}

/*
==================================================
    /Home page/Houses
==================================================
*/
.houses-header {
  width: calc(4.5 / 12 * 100%);
}

.card {
  width: 35%;
}


/*
==================================================
    /House/Details
==================================================
*/
.house-title {
  @include title-fz-2;
}

.details-title {
  @include title-fz-3;
}

.carousel-container {
  margin-left: get-contained-side-spacing('xl');
}

.other-house-title {
  @include title-fz-3;
}

.other-house-img {
  height: 600px;
  object-fit: cover;
}

.alert-container {
  @include alert-container-xl;
}
