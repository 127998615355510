@import "../spacing/functions";

@mixin alert-container {
  position: absolute;
  margin-top: get-contained-side-spacing('xs');
  top: 0;
  left: 0;
  right: 0;
  z-index: 10001;
}

@mixin alert {
  &-out {
    transform: translateY(-100%);
    opacity: 0;
  }

  transition: transform .3s, opacity .3s;

  background: #fff;
  padding: get-contained-side-spacing('xs') / 2;
  box-shadow: 1px 1px 20px 0 rgba(0,0,0,.1);
  position: relative;
  margin-bottom: get-contained-side-spacing('xs') / 4;

  &::after {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 4px;
    display: block;
  }
}

@mixin success {
  &::after {
    background: green;
  }
}

@mixin danger {
  &::after {
    background: red;
  }
}

@mixin alert-container-lg {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

@mixin alert-container-xl {
  width: 30%;
}
