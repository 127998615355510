@import "./variables.scss";

@mixin title-fz-1 {
  font-size: 6rem;
}

@mixin title-fz-2 {
  font-size: 3.75rem;
}

@mixin title-fz-3 {
  font-size: 3rem;
}

@mixin title-fz-4 {
  font-size: 2.125rem;
}

@mixin title-fz-5 {
  font-size: 1.5rem;
}

@mixin title-fz-6 {
  font-size: 1.25rem;
}

/**
  Make rules for level 1 title
 */
@mixin title-1 {
  @include title-fz-1;
  color: $base-title-font-color;
  font-family: $title-font-family;
}

/**
  Make rules for level 2 title
 */
@mixin title-2 {
  @include title-fz-2;
  color: $base-title-font-color;
  font-family: $title-font-family;
}

/**
  Make rules for level 3 title
 */
@mixin title-3 {
  @include title-fz-3;
  color: $base-title-font-color;
  font-family: $title-font-family;
}

/**
  Make rules for level 4 title
 */
@mixin title-4 {
  @include title-fz-4;
  color: $base-title-font-color;
  font-family: $title-font-family;
}

/**
  Make rules for level 5 title
 */
@mixin title-5 {
  @include title-fz-5;
  color: $base-title-font-color;
  font-family: $title-font-family;
}


/**
  Make rules for level 6 title
 */
@mixin title-6 {
  @include title-fz-6;
  color: $base-title-font-color;
  font-family: $title-font-family;
}


/**
  Make rules for level 1 subtitle
 */
@mixin subtitle-1 {
  font-family: $body-font-family;
  font-size: 1rem;

  color: $base-body-font-color;
}

/**
  Make rules for level 2 subtitle
 */
@mixin subtitle-2 {
  font-family: $body-font-family;
  font-size: .875rem;
  font-weight: 500;

  color: $base-body-font-color;
}

@mixin body-fz-1 {
  font-size: 1rem;
}

/**
  Make rules for level 1 body
 */
@mixin body-1 {
  font-family: $body-font-family;
  @include body-fz-1;
  color: $base-body-font-color;
}

@mixin body-fz-2 {
  font-size: .875rem;
}

/**
  Make rules for level 1 body
 */
@mixin body-2 {
  font-family: $body-font-family;
  @include body-fz-2;

  color: $base-body-font-color;
}

/**
  Make rules for buttons
 */
@mixin button {
  font-family: $body-font-family;
  font-weight: 500;
  font-size: .875rem;
  text-transform: uppercase;
}

/**
  Make rules for captions
 */
@mixin caption {
  font-size: .75rem;
  font-weight: 500;
  font-family: $body-font-family;
  color: $base-body-font-color;
}

/**
  Make rules for overlines
 */
@mixin overline {
  font-size: 0.625rem;
  font-weight: 500;
  font-family: $body-font-family;
  color: $base-body-font-color;
}

/**
  Make rules to make a text bold
 */
@mixin bold {
  font-weight: 700;
}

/**
  Make rule to use brand typography
 */
@mixin brand {
  font-family: $brand-font-family !important;
}
